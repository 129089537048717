<script>
    import { onMount, createEventDispatcher } from "svelte";
    import { ClientService } from "./service";
    import {
        useForm,
        required,
        validators,
        email,
        Hint,
        HintGroup,
    } from "svelte-use-form";
    import { userInformation } from "../auth.service";

    const dataForm = useForm();
    let loading = true;
    let userData = {
        email: "",
    };
    let disabledButton = false;

    $: {
        disabledButton = !$dataForm.valid;
    }

    onMount(() => {
        loading = false;
    });

    const backModal = () => dispatch("back", true);

    const dispatch = createEventDispatcher();

    const handleSubmit = (e) => {
        if ($dataForm.valid) {
            disabledButton = true;

            ClientService.UpdateEmailById(
                {
                    id: $userInformation.user.id,
                },
                userData,
            )
                .then((response) =>
                    response.ok ? response : Promise.reject(response),
                )
                .then(() => {
                    dispatch("successful", true);
                })
                .catch((err) => {
                    console.error(err);
                    disabledButton = false;
                });
        }
    };
</script>

<!-- modal-dialog-scrollable -->
{#if loading}
    <!-- Loading State -->
    <div class="modal-body">Cargando...</div>
{:else}
    <!-- Modal Header -->
    <div class="modal-header">
        <button
            type="button"
            class="btn btn-link btn-sm d-flex align-items-center"
            on:click={backModal}
        >
            <span class="material-icons">arrow_back_ios</span>
        </button>
        <h5 class="modal-title flex-grow-1">Cambiar email</h5>
    </div>

    <!-- Modal Body -->
    <div class="modal-body">
        <form use:dataForm>
            <div class="modal-body">
                <div class="mb-3">
                    <label for="email" class="form-label">
                        Nuevo correo electrónico
                    </label>
                    <input
                        use:validators={[required, email]}
                        type="email"
                        class="form-control {!$dataForm.email?.valid
                            ? 'is-invalid'
                            : 'is-valid'}"
                        name="email"
                        id="email"
                        required
                        autocomplete="email"
                        bind:value={userData.email}
                    />
                    <HintGroup for="email">
                        <Hint on="required" class="form-text invalid-feedback"
                            >El correo electrónico es obligatorio.</Hint
                        >
                        <Hint
                            on="email"
                            class="form-text invalid-feedback"
                            hideWhen="required"
                            >El formato del correo electrónico no es correcto.</Hint
                        >
                    </HintGroup>
                </div>
            </div>
        </form>
    </div>

    <!-- Modal Footer -->
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" on:click={backModal}
            >Cerrar</button
        >
        <button
            type="submit"
            class="btn btn-primary"
            disabled={!$dataForm.valid || disabledButton}
            on:click={handleSubmit}>Guardar</button
        >
    </div>
{/if}
