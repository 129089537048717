<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import { userInformation } from "../../../auth.service";
    import CreateUpdateComponent from "./create-update.component.svelte";

    import ModalConfirmComponent from "../../../components/modal-confirm.component.svelte";
    import { UserTypesService } from "./service";

    let loading = true;
    let userTypes = [];
    let disabledAllButton = false;
    let modalUpdateUserType = null;
    let modalConfirmDelete = null;
    let configModalConfirmDelete = {
        iconClosed: true,
        title: "Eliminar tipo de usuario",
        description: "¿Estas seguro que deseas eliminar #name#?",
        buttonCancel: "Cancel",
        buttonSubmit: "Delete",
        typeSubmit: "danger",
        disabledButton: false,
    };

    onMount(() => {
        loading = true;

        initComponent();
    });

    const initComponent = () => {
        loading = true;
        UserTypesService.list()
            .then((response) =>
                response.ok ? response.json() : Promise.reject(response),
            )
            .then((response) => {
                userTypes = response;

                loading = false;
            });
    };

    const CreateUpdateUserTypeEvent = (event) => {
        modalUpdateUserType = null;
        initComponent();
    };

    const openModalConfirmDelete = (type) => {
        configModalConfirmDelete.description =
            configModalConfirmDelete.description.replace(
                new RegExp("#name#", "ig"),
                `${type.name}`,
            );
        modalConfirmDelete = type;
    };

    const closeModalConfirmDelete = (user) => {
        configModalConfirmDelete.description =
            configModalConfirmDelete.description.replace(
                new RegExp(`${modalConfirmDelete.name}`, "ig"),
                "#name#",
            );
        modalConfirmDelete = null;
    };

    const deleteUserById = () => {
        if (!disabledAllButton) {
            disabledAllButton = true;
            UserTypesService.deleteUserType(modalConfirmDelete.id)
                .then((response) =>
                    response.ok ? response : Promise.reject(response),
                )
                .then((response) => {
                    disabledAllButton = false;
                    closeModalConfirmDelete();
                    initComponent();
                })
                .catch((err) => {
                    console.error(err);
                    disabledAllButton = false;
                });
        }
    };
</script>

{#if loading}
    <div
        class="d-flex justify-content-center align-items-center"
        style="min-height: 10rem;"
    >
        <div
            class="spinner-border text-dark mt-3"
            role="status"
            style="width: 4rem; height: 4rem;"
        >
            <span class="visually-hidden">Cargando...</span>
        </div>
    </div>
{:else}
    <div style="min-height: 10rem;">
        <!-- Search and Filter Row -->
        <div class="row mb-3">
            <div class="col-md-8">
                <input
                    type="text"
                    class="form-control"
                    placeholder="Search for user types"
                />
            </div>
            <div class="col-md-4">
                <select class="form-select">
                    <option selected>Search by: User</option>
                    <option value="1">Option 1</option>
                    <option value="2">Option 2</option>
                    <option value="3">Option 3</option>
                </select>
            </div>
        </div>

        <!-- User List -->
        {#each userTypes as type}
            <div class="row align-items-center border-bottom py-2">
                <div class="col-auto">
                    <img
                        src={type.picture}
                        class="rounded-circle"
                        alt={type.name}
                    />
                </div>
                <div class="col">
                    <div class="fw-bold">{type.name}</div>
                    <!-- <div>{type.email}</div> -->
                    <!-- <p class="card-text">
                        {#if type.usertype}
                            <span class="badge bg-success"
                                >{type.usertype.name}</span
                            >
                        {:else}
                            <span class="badge bg-secondary">Sin asignar</span>
                        {/if}
                    </p> -->
                </div>
                <!-- <div class="col d-none d-md-block">98</div>
                <div class="col d-none d-sm-block">4 hours ago</div> -->
                <div class="col text-end">
                    <div class="dropdown">
                        <button
                            class="btn btn-link btn-sm"
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i class="material-icons">more_vert</i>
                        </button>
                        <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                        >
                            <li>
                                <!-- svelte-ignore a11y-invalid-attribute -->
                                <a
                                    class="dropdown-item"
                                    href="javascript:;"
                                    on:click|preventDefault={() =>
                                        (modalUpdateUserType = type)}>Editar</a
                                >
                            </li>
                            {#if type.id != $userInformation.id}
                                <li>
                                    <!-- svelte-ignore a11y-invalid-attribute -->
                                    <a
                                        class="dropdown-item"
                                        href="javascript:;"
                                        on:click|preventDefault={() =>
                                            openModalConfirmDelete(type)}
                                        >Eliminar</a
                                    >
                                </li>
                            {/if}
                        </ul>
                    </div>
                </div>
            </div>
        {/each}
    </div>
{/if}

{#if modalConfirmDelete}
    <ModalConfirmComponent
        bind:config={configModalConfirmDelete}
        on:close={closeModalConfirmDelete}
        on:submit={deleteUserById}
    ></ModalConfirmComponent>
{/if}

{#if modalUpdateUserType}
    <CreateUpdateComponent
        bind:type={modalUpdateUserType}
        on:successful={CreateUpdateUserTypeEvent}
        on:close={() => (modalUpdateUserType = null)}
    ></CreateUpdateComponent>
{/if}
