<script>
    import { onMount, createEventDispatcher } from "svelte";

    export let config;
    let loading = true;
    let modalBackdrop;

    onMount(() => {
        modalBackdrop.addEventListener("click", closeModal);

        loading = true;
        loading = false;
    });

    const closeModal = () => dispatch("close", true);

    const dispatch = createEventDispatcher();

    const Ok = () => dispatch("submit", true);
</script>

<!-- modal-dialog-scrollable -->
<div bind:this={modalBackdrop} class="modal-backdrop fade show" />
<div class="modal fade show" role="dialog">
    <div class="modal-content">
        {#if loading}
            <!-- Loading State -->
            <div class="modal-body">Cargando...</div>
        {:else}
            <!-- Modal Header -->
            <div class="modal-header">
                <h5 class="modal-title">
                    {config.title}
                </h5>
                <button type="button" class="btn-close" on:click={closeModal}
                ></button>
            </div>

            <!-- Modal Body -->
            <div class="modal-body">
                {config.description}
            </div>

            <!-- Modal Footer -->
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-light"
                    on:click={closeModal}
                >
                    {config.buttonCancel}
                </button>
                <button
                    type="submit"
                    class="btn btn-{config.typeSubmit}"
                    disabled={config.disabledButton}
                    on:click={Ok}
                >
                    {config.buttonSubmit}
                </button>
            </div>
        {/if}
    </div>
</div>
