import HomeRouters from "./home/Routes";

import SettingsRouters from "./settings/Routes";
import DashboardRouters from "./dashboard/Routes";

import LoginRouters from "./login/Routes";

const flattenRoutes = (routes, parentPath = '') => {
  let result = [];

  routes.forEach(route => {
    // Construir la ruta completa
    let fullPath = route.path ? parentPath + '/' + route.path : parentPath;
    fullPath = fullPath.replace(/\/+/g, '/'); // Eliminar barras duplicadas

    // Añadir la ruta al resultado si tiene un componente definido
    if (route.component || route.redirectTo) {
      result.push(
        Object.assign({}, {
          path: fullPath === '' ? '/' : fullPath,
          isAuthorized: route.isAuthorized,
          isAuthenticated: route.isAuthenticated
        }, route.component
          ? { component: route.component }
          : {}, route.redirectTo
          ? { redirectTo: route.redirectTo }
          : {})
      );
    }

    // Procesar rutas hijas si existen
    if (route.children) {
      result = result.concat(flattenRoutes(route.children, fullPath).map(o => Object.assign({}, o, {
        isAuthorized: o.isAuthorized ? o.isAuthorized : route.isAuthorized,
        isAuthenticated: o.isAuthenticated ? o.isAuthenticated : route.isAuthenticated
      })));
    }
  });

  return result;
};

export const routes = flattenRoutes([
  { path: "/", isAuthenticated: true, children: HomeRouters },
  { path: "/settings", isAuthenticated: true, children: SettingsRouters },
  { path: "/dashboard", isAuthenticated: true, children: DashboardRouters },
  { path: "/login", isAuthenticated: false, children: LoginRouters },
  { path: "**" },
]);
