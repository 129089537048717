import { writable } from "svelte/store";
import { LoginService } from "./login/service";

export const isAuthenticated = writable(false);
export const userInformation = writable(null);
export const Authentication = {
    access_token: null
};
export const scopes = [];

const handlerLogin = () => {
    if (localStorage.getItem('auth')) {
        Authentication.access_token = JSON.parse(localStorage.getItem('auth')).access_token;
    }
    return LoginService.getLogin();
}

const Logout = () =>
    LoginService.logout();

export const AuthService = ({
    handlerLogin,
    Logout
});