<script>
    import { createEventDispatcher, onMount } from "svelte";
    import {
        parseISO,
        format,
        isBefore,
        isAfter,
        startOfDay,
        endOfDay,
    } from "date-fns";

    export let date = [null, null];

    const dispatch = createEventDispatcher();

    const formatDate = (date) => (date ? format(date, "yyyy-MM-dd") : "");

    const handleStartDateChange = (event) => {
        const newStartDate = startOfDay(parseISO(event.target.value));
        let newEndDate = date[1];

        if (!newEndDate || isBefore(newEndDate, newStartDate)) {
            newEndDate = endOfDay(newStartDate);
        }

        date = [newStartDate, newEndDate];
        dispatch("change", date);
    };

    const handleEndDateChange = (event) => {
        const newEndDate = endOfDay(parseISO(event.target.value));
        let newStartDate = date[0];

        if (!newStartDate || isAfter(newStartDate, newEndDate)) {
            newStartDate = startOfDay(newEndDate);
        }

        date = [newStartDate, newEndDate];
        dispatch("change", date);
    };

    const resetDate = () => {
        dispatch("reset");
    };
</script>

<div class="d-flex align-items-center">
    <input
        type="date"
        class="form-control mr-2"
        value={formatDate(date[0])}
        on:change={handleStartDateChange}
        max={date[1] ? formatDate(date[1]) : ""}
    />
    <input
        type="date"
        class="form-control"
        value={formatDate(date[1])}
        on:change={handleEndDateChange}
        min={date[0] ? formatDate(date[0]) : ""}
    />
    <button class="btn btn-link ml-2 p-0" on:click={resetDate}>
        <i class="material-icons">refresh</i>
    </button>
</div>
