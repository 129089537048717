<script>
    import { AuthService, userInformation } from "../auth.service";
    import { isSidebarCollapsed } from "./service";
    import AccountComponent from "../clients/account.component.svelte";
    import { modeDark } from "../manager-color";

    let modalAccount = false;

    const toggleSidebar = (event) => {
        if (event) {
            event.stopPropagation(); // Esto detiene la propagación del evento
        }
        $isSidebarCollapsed = !$isSidebarCollapsed;
    };

    const UpdateAccountEvent = (event) => {
        modalAccount = false;
    };

    const changeMode = (e) => {
        $modeDark = !$modeDark;
    };
</script>

<nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container-fluid">
        <!-- Botón para contraer/desplegar el sidebar -->
        <button class="btn text-white" on:click={toggleSidebar}>
            <span class="material-icons"
                >menu</span
            >
        </button>

        <!-- Logo -->
        <a class="navbar-brand ms-3" href="/">
            <img src="/images/logo-dark.svg" alt="Logo" height="30" />
        </a>

        <!-- Elementos a la derecha -->
        <div class="ms-auto d-flex align-items-center">
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a
                class="nav-link mx-2 {$modeDark == true ? 'active' : ''}"
                href="javascript:;"
                on:click|preventDefault={changeMode}
            >
                <span class="material-icons">
                    {$modeDark ? "dark_mode" : "light_mode"}
                </span>
            </a>
            <!-- svelte-ignore a11y-invalid-attribute -->
            <!-- <a
                class="nav-link position-relative mx-2"
                href="javascript:;"
                on:click|preventDefault={() => alert("Not found")}
            >
                <span class="material-icons">notifications</span>
                <span
                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                    >3</span
                >
            </a> -->
            <!-- <a class="nav-link position-relative mx-2" href="#">
                <span class="material-icons">mail</span>
                <span
                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                    >5</span
                >
            </a> -->
            <div class="nav-item dropdown mx-2">
                <!-- svelte-ignore a11y-invalid-attribute -->
                <a
                    class="nav-link dropdown-toggle"
                    href="javascript:;"
                    id="navbarDropdownMenuProfile"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <img
                        src={$userInformation.picture}
                        alt="Profile"
                        class="rounded-circle mx-2"
                        style="width: 32px; height: 32px;"
                    />
                    <span class="d-none d-sm-block">
                        {$userInformation.firstName}
                        {$userInformation.lastName}
                    </span>
                </a>
                <ul
                    class="dropdown-menu dropdown-menu-dark dropdown-menu-end"
                    aria-labelledby="navbarDropdownMenuProfile"
                >
                    <li class="dropdown-item">
                        <div class="d-flex align-items-center">
                            <img
                                src={$userInformation.picture}
                                alt="Profile"
                                class="rounded-circle me-2"
                                style="width: 50px; height: 50px;"
                            />
                            <div>
                                <h6 class="mb-0">
                                    {$userInformation.firstName}
                                    {$userInformation.lastName}
                                </h6>
                                <small class="d-block text-muted">
                                    {$userInformation.email}
                                </small>
                            </div>
                        </div>
                    </li>
                    <li>
                        <hr class="dropdown-divider" />
                    </li>
                    <li class="dropdown-item">
                        <!-- svelte-ignore a11y-invalid-attribute -->
                        <a
                            href="javascript:;"
                            class="dropdown-item d-block"
                            on:click|preventDefault={() =>
                                (modalAccount = true)}
                            >Configuración de la cuenta</a
                        >
                    </li>
                    <li>
                        <hr class="dropdown-divider" />
                    </li>
                    <li class="dropdown-item">
                        <!-- svelte-ignore a11y-invalid-attribute -->
                        <a
                            href="javascript:;"
                            class="dropdown-item d-block"
                            on:click|preventDefault={AuthService.Logout}
                            >Logout</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>

{#if modalAccount}
    <AccountComponent
        on:successful={UpdateAccountEvent}
        on:close={() => (modalAccount = false)}
    ></AccountComponent>
{/if}
