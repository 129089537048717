import { environments } from "../../../assets/environments";
import { httpDelete, httpGet, httpPost, httpPut } from "../../../http-service.svelte";

const listUserTypes = () =>
    httpGet(
        `${environments.administrationApi}/user-types`
    );

const list = () =>
    httpGet(
        `${environments.administrationApi}/administrators`
    );

const deleteAdmin = (id) =>
    httpDelete(
        `${environments.administrationApi}/administrator/${id}`,
        {}
    );

const detail = (id) =>
    httpGet(
        `${environments.administrationApi}/administrator/${id}`
    );

const create = (input) =>
    httpPost(
        `${environments.administrationApi}/administrator`,
        input
    );

const update = (id, input) =>
    httpPut(
        `${environments.administrationApi}/administrator/${id}`,
        input
    );

export const AdministratorsService = ({
    listUserTypes,
    list,
    deleteAdmin,
    detail,
    create,
    update
});