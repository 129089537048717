import { environments } from "../../../assets/environments";
import { httpDelete, httpGet, httpPost, httpPut } from "../../../http-service.svelte";

const listPermissions = () =>
    httpGet(
        `${environments.administrationApi}/permissions`
    );

const list = () =>
    httpGet(
        `${environments.administrationApi}/user-types`
    );

const deleteUserType = (id) =>
    httpDelete(
        `${environments.administrationApi}/user-type/${id}`,
        {}
    );

const detail = (id) =>
    httpGet(
        `${environments.administrationApi}/user-type/${id}`
    );

const create = (input) =>
    httpPost(
        `${environments.administrationApi}/user-type`,
        input
    );

const update = (id, input) =>
    httpPut(
        `${environments.administrationApi}/user-type/${id}`,
        input
    );

export const UserTypesService = ({
    listPermissions,
    list,
    deleteUserType,
    detail,
    create,
    update
});