<script>
    import { onMount, createEventDispatcher } from "svelte";
    import { ClientService } from "./service";
    import {
        useForm,
        required,
        validators,
        email,
        minLength,
        maxLength,
        Hint,
        HintGroup,
    } from "svelte-use-form";
    import { AuthService, userInformation } from "../auth.service";
    import ChangeEmailComponent from "./change-email.component.svelte";
    import ChangePasswordComponent from "./change-password.component.svelte";

    const dataForm = useForm();
    let loading = true;
    let userData = {
        firstName: "",
        lastName: "",
        email: "",
    };
    let disabledButton = false;
    let modalBackdrop;
    let modalChangePassword = false;
    let modalChangeEmail = false;

    $: {
        disabledButton = !$dataForm.valid;
    }

    onMount(() => {
        modalBackdrop.addEventListener("click", closeModal);

        loading = true;
        ClientService.GetClientById({
            id: $userInformation.id,
        })
            .then((response) =>
                response.ok ? response.json() : Promise.reject(response),
            )
            .then((response) => {
                userData = Object.assign({}, userData, response);
                loading = false;
            })
            .catch((err) => {
                console.error(err);
            });
    });

    const closeModal = () => dispatch("close", true);

    const dispatch = createEventDispatcher();

    const handleSubmit = (e) => {
        if ($dataForm.valid) {
            disabledButton = true;

            ClientService.UpdateClientById(
                {
                    id: $userInformation.id,
                },
                userData,
            )
                .then((response) =>
                    response.ok ? response : Promise.reject(response),
                )
                .then(() => {
                    dispatch("successful", true);
                })
                .catch((err) => {
                    console.error(err);
                    disabledButton = false;
                });
        }
    };

    const UpdateChangeEmailEvent = () => {
        AuthService.Logout();
        modalChangeEmail = false;
    };
    const UpdateChangePasswordEvent = () => {
        AuthService.Logout();
        modalChangePassword = false;
    };
</script>

<!-- modal-dialog-scrollable -->
<div bind:this={modalBackdrop} class="modal-backdrop fade show" />
<div class="modal modal-right fade show" role="dialog">
    <div class="modal-content">
        {#if loading}
            <!-- Loading State -->
            <div class="modal-body">Cargando...</div>
        {:else if modalChangeEmail}
            <ChangeEmailComponent
                on:successful={UpdateChangeEmailEvent}
                on:back={() => (modalChangeEmail = false)}
            ></ChangeEmailComponent>
        {:else if modalChangePassword}
            <ChangePasswordComponent
                on:successful={UpdateChangePasswordEvent}
                on:back={() => (modalChangePassword = false)}
            ></ChangePasswordComponent>
        {:else}
            <!-- Modal Header -->
            <div class="modal-header">
                <h5 class="modal-title">Mi cuenta</h5>
                <button type="button" class="btn-close" on:click={closeModal}
                ></button>
            </div>

            <!-- Modal Body -->
            <div class="modal-body">
                <form use:dataForm>
                    <div class="modal-body">
                        <div class="text-center mb-4">
                            <img
                                src={userData.picture}
                                class="rounded-circle"
                                alt="Foto de Perfil"
                                style="width: 120px; height: 120px;"
                            />
                            {#if false}
                                <div class="mt-2">
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        >Cambiar Foto</button
                                    >
                                    {#if !userData.picture.includes("i1.wp.com")}
                                        <button
                                            type="button"
                                            class="btn btn-secondary btn-sm"
                                            >Restaurar Foto</button
                                        >
                                    {/if}
                                </div>
                            {/if}
                        </div>

                        <div class="mb-3">
                            <label for="firstName" class="form-label">
                                Nombre
                            </label>
                            <input
                                use:validators={[
                                    required,
                                    minLength(3),
                                    maxLength(20),
                                ]}
                                type="text"
                                class="form-control {!$dataForm.firstName?.valid
                                    ? 'is-invalid'
                                    : 'is-valid'}"
                                name="firstName"
                                id="firstName"
                                required
                                autocomplete="given-name"
                                minlength="3"
                                maxlength="20"
                                bind:value={userData.firstName}
                            />
                            <HintGroup for="firstName">
                                <Hint
                                    on="required"
                                    class="form-text invalid-feedback"
                                    >El campo es obligatorio.</Hint
                                >
                                <!-- Se muestra solo si el campo no está vacío (hideWhen="required") -->
                                <Hint
                                    on="minLength"
                                    class="form-text invalid-feedback"
                                    hideWhen="required"
                                    let:value
                                    >El nombre debe tener al menos {value}
                                    caracter.</Hint
                                >
                                <!-- Se muestra solo si el campo supera la longitud mínima (hideWhen="minLength") -->
                                <Hint
                                    on="maxLength"
                                    class="form-text invalid-feedback"
                                    hideWhen="minLength"
                                    let:value
                                    >El nombre no debe exceder los {value}
                                    caracteres.</Hint
                                >
                            </HintGroup>
                        </div>
                        <div class="mb-3">
                            <label for="lastName" class="form-label">
                                Apellido
                            </label>
                            <input
                                use:validators={[
                                    required,
                                    minLength(3),
                                    maxLength(20),
                                ]}
                                type="text"
                                class="form-control {!$dataForm.lastName?.valid
                                    ? 'is-invalid'
                                    : 'is-valid'}"
                                name="lastName"
                                id="lastName"
                                required
                                autocomplete="family-name"
                                minlength="3"
                                maxlength="20"
                                bind:value={userData.lastName}
                            />
                            <HintGroup for="lastName">
                                <Hint
                                    on="required"
                                    class="form-text invalid-feedback"
                                    >El campo es obligatorio.</Hint
                                >
                                <!-- Se muestra solo si el campo no está vacío (hideWhen="required") -->
                                <Hint
                                    on="minLength"
                                    class="form-text invalid-feedback"
                                    hideWhen="required"
                                    let:value
                                    >El nombre debe tener al menos {value}
                                    caracter.</Hint
                                >
                                <!-- Se muestra solo si el campo supera la longitud mínima (hideWhen="minLength") -->
                                <Hint
                                    on="maxLength"
                                    class="form-text invalid-feedback"
                                    hideWhen="minLength"
                                    let:value
                                    >El nombre no debe exceder los {value}
                                    caracteres.</Hint
                                >
                            </HintGroup>
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">
                                Correo electrónico
                            </label>
                            <input
                                use:validators={[required, email]}
                                type="email"
                                class="form-control {!$dataForm.email?.valid
                                    ? 'is-invalid'
                                    : 'is-valid'}"
                                name="email"
                                id="email"
                                required
                                autocomplete="email"
                                disabled
                                bind:value={userData.email}
                            />
                            <HintGroup for="email">
                                <Hint
                                    on="required"
                                    class="form-text invalid-feedback"
                                    >El correo electrónico es obligatorio.</Hint
                                >
                                <Hint
                                    on="email"
                                    class="form-text invalid-feedback"
                                    hideWhen="required"
                                    >El formato del correo electrónico no es
                                    correcto.</Hint
                                >
                            </HintGroup>

                            <div id="emailHelp" class="form-text">
                                <!-- svelte-ignore a11y-invalid-attribute -->
                                Si necesitas cambiar tu correo electrónico,
                                <a
                                    href="javascript:;"
                                    on:click|preventDefault={() =>
                                        (modalChangeEmail = true)}
                                    >haz clic aquí</a
                                >.
                            </div>
                        </div>

                        <div class="mb-3">
                            <h6>Cambiar Contraseña</h6>
                            <p>
                                <!-- svelte-ignore a11y-invalid-attribute -->
                                Si deseas cambiar tu contraseña,
                                <a
                                    href="javascript:;"
                                    on:click|preventDefault={() =>
                                        (modalChangePassword = true)}
                                    >haz clic aquí</a
                                >.
                            </p>
                        </div>
                    </div>
                </form>
            </div>

            <!-- Modal Footer -->
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    on:click={closeModal}>Cerrar</button
                >
                <button
                    type="submit"
                    class="btn btn-primary"
                    disabled={!$dataForm.valid || disabledButton}
                    on:click={handleSubmit}>Guardar</button
                >
            </div>
        {/if}
    </div>
</div>
