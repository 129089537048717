<script>
    import { onMount, createEventDispatcher } from "svelte";
    import { _ } from "svelte-i18n";
    import { UserTypesService } from "./service";
    import PermissionsComponent from "./permissions.svelte";
    import {
        useForm,
        required,
        validators,
        maxLength,
        minLength,
        Hint,
        HintGroup,
    } from "svelte-use-form";
    import { userInformation } from "../../../auth.service";

    export let type = null;
    const dataForm = useForm();
    let loading = true;
    let dataUserType = {
        name: "",
        description: "",
        scopes: [],
    };
    let permissions = [];
    let disabledButton = false;
    let modalBackdrop;

    $: {
        disabledButton = !$dataForm.valid;
    }

    onMount(() => {
        modalBackdrop.addEventListener("click", closeModal);

        loading = true;
        UserTypesService.listPermissions()
            .then((response) =>
                response.ok ? response.json() : Promise.reject(response),
            )
            .then((permissionsResponse) => {
                permissions = permissionsResponse;
                if (type) {
                    UserTypesService.detail(type.id)
                        .then((response) =>
                            response.ok
                                ? response.json()
                                : Promise.reject(response),
                        )
                        .then((response) => {
                            dataUserType = Object.assign(
                                {},
                                dataUserType,
                                response,
                            );

                            permissions = permissions.map((p) =>
                                Object.assign(
                                    {},
                                    p,
                                    {
                                        checked: dataUserType.scopes.find(
                                            (s) => s == p.codename,
                                        )
                                            ? true
                                            : false,
                                    },
                                    p.permissions
                                        ? {
                                              permissions: p.permissions.map(
                                                  (p2) =>
                                                      Object.assign({}, p2, {
                                                          checked:
                                                              dataUserType.scopes.find(
                                                                  (s) =>
                                                                      s ==
                                                                      p2.codename,
                                                              )
                                                                  ? true
                                                                  : false,
                                                      }),
                                              ),
                                          }
                                        : {},
                                ),
                            );

                            loading = false;
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                } else {
                    loading = false;
                }
            })
            .catch((err) => {
                console.error(err);
                closeModal();
            });
    });

    const closeModal = () => dispatch("close", true);

    const dispatch = createEventDispatcher();

    const handleSubmit = (e) => {
        disabledButton = true;
        if (dataUserType.id) {
            UserTypesService.update(dataUserType.id, dataUserType)
                .then((response) =>
                    response.ok ? response : Promise.reject(response),
                )
                .then((response) => {
                    dispatch("successful", true);
                })
                .catch((err) => {
                    console.error(err);
                    disabledButton = false;
                });
        } else {
            UserTypesService.create(dataUserType)
                .then((response) =>
                    response.ok ? response.json() : Promise.reject(response),
                )
                .then((response) => {
                    dispatch("successful", response);
                })
                .catch((err) => {
                    console.error(err);
                    disabledButton = false;
                });
        }
    };

    const updatePermissions = (e) => {
        if (dataUserType.scopes.find((s) => s == e.detail.detail.codename)) {
            dataUserType.scopes = dataUserType.scopes.filter(
                (s) => s != e.detail.detail.codename,
            );
        } else {
            dataUserType.scopes.push(e.detail.detail.codename);
        }
    };
</script>

<!-- modal-dialog-scrollable -->
<div bind:this={modalBackdrop} class="modal-backdrop fade show" />
<div class="modal modal-right fade show" role="dialog">
    <div class="modal-content">
        {#if loading}
            <!-- Loading State -->
            <div class="modal-body">Cargando...</div>
        {:else}
            <!-- Modal Header -->
            <div class="modal-header">
                <h5 class="modal-title">
                    {#if type}
                        Modificar {type.name}
                    {:else}
                        Agregar tipo de usuario
                    {/if}
                </h5>
                <button type="button" class="btn-close" on:click={closeModal}
                ></button>
            </div>

            <!-- Modal Body -->
            <div class="modal-body">
                <form use:dataForm>
                    <div class="mb-3">
                        <label for="name" class="col-form-label"
                            >Nombre del tipo de usuario:</label
                        >
                        <!-- svelte-ignore a11y-autofocus -->
                        <input
                            use:validators={[
                                required,
                                minLength(3),
                                maxLength(50),
                            ]}
                            type="text"
                            class="form-control {$dataForm.name?.touched
                                ? !$dataForm.name?.valid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                : ''}"
                            id="name"
                            name="name"
                            maxlength="50"
                            minlength="3"
                            bind:value={dataUserType.name}
                            autofocus
                            autocomplete="off"
                            required
                        />
                        <HintGroup for="name">
                            <Hint
                                on="required"
                                class="form-text invalid-feedback"
                                >Este campo es requerido</Hint
                            >
                            <Hint
                                on="minLength"
                                class="form-text invalid-feedback"
                                hideWhenRequired
                                let:value
                            >
                                Minimo de caracteres: {value}
                            </Hint>
                            <Hint
                                on="maxLength"
                                class="form-text invalid-feedback"
                                hideWhenRequired
                                let:value
                            >
                                Máximo de caracteres: {value}
                            </Hint>
                        </HintGroup>
                    </div>
                    <div class="mb-3">
                        <label for="description" class="col-form-label"
                            >Description:</label
                        >
                        <textarea
                            class="form-control"
                            id="description"
                            name="description"
                            maxlength="200"
                            minlength="3"
                            bind:value={dataUserType.description}
                            autocomplete="off"
                        />
                    </div>

                    <div class="mb-3">
                        <PermissionsComponent
                            on:checked={updatePermissions}
                            {permissions}
                        ></PermissionsComponent>
                    </div>
                </form>
            </div>

            <!-- Modal Footer -->
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    on:click={closeModal}>Cerrar</button
                >
                <button
                    type="submit"
                    class="btn btn-primary"
                    disabled={!$dataForm.valid || disabledButton}
                    on:click={handleSubmit}>Guardar</button
                >
            </div>
        {/if}
    </div>
</div>
