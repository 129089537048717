<script>
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import { createEventDispatcher } from "svelte";

  export let config = {
    breadcrumb: [],
    title: "",
    description: "",
    buttons: [],
  };

  /*
    let configTitle = {
    breadcrumb: [
      { title: "Home", link: "/" },
      { title: "Tenant", link: "/tenant" },
    ],
    title: "Página de Artículo",
    description:
      "Esta es la descripción de la página del artículo seleccionado.",
    buttons: [
      {
        text: "Botón 1",
        icon: "fa-plus",
        class: "btn-success",
        link: "/nuevo",
      },
      {
        icon: "fa-refresh",
        class: "btn-secondary",
        action: { type: "event", event: "refreshClicked", detail: {} },
      },
      {
        text: "Logout",
        class: "btn-danger",
        action: { type: "event", event: "logoutClicked", detail: {} },
      },
    ],
  };
  */

  const dispatch = createEventDispatcher();

  onMount(() => {});

  const handleButtonClick = (action) => {
    if (action.type === "event") {
      dispatch(action.event, action.detail);
    }
    // No navigation action needed for link type buttons as it's handled by <a> tag
  };
</script>

<header>
  <div class="container-fluid">
    <!-- Breadcrumb -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        {#if !config.breadcrumb || (config.breadcrumb && config.breadcrumb.length === 0)}
          <li class="breadcrumb-item active" aria-current="page"></li>
        {:else}
          {#if config.breadcrumb.length > 3}
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-link"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                ...
              </button>
              <ul class="dropdown-menu">
                {#each config.breadcrumb.slice(0, -3).reverse() as crumb}
                  <li>
                    <a class="dropdown-item" href={crumb.link}>{crumb.title}</a>
                  </li>
                {/each}
              </ul>
            </div>
          {/if}

          {#each config.breadcrumb.slice(-3) as crumb, index (crumb.title)}
            {#if index === config.breadcrumb.length - 1}
              <li class="breadcrumb-item active" aria-current="page">
                {crumb.title}
              </li>
            {:else}
              <li class="breadcrumb-item">
                <a href={crumb.link}>{crumb.title}</a>
              </li>
            {/if}
          {/each}
        {/if}
      </ol>
    </nav>

    <!-- Title and Description -->
    <div class="row">
      <div class="col">
        <h1>{config.title}</h1>
        <p>{config.description || ""}</p>
      </div>
      <div class="col-auto">
        {#if config.buttons && config.buttons.length > 0}
          <div class="btn-group" role="group" aria-label="Button group">
            {#each config.buttons as button}
              {#if button.link}
                <a href={button.link} class={`btn ${button.class}`}>
                  {#if button.icon}<i class={`fa ${button.icon}`}></i>{/if}
                  {#if button.text}{button.text}{/if}
                </a>
              {:else}
                <button
                  type="button"
                  class={`btn ${button.class}`}
                  on:click={() => handleButtonClick(button.action)}
                >
                  {#if button.icon}<i class="material-icons">{button.icon}</i
                    >{/if}
                  {#if button.text}{button.text}{/if}
                </button>
              {/if}
            {/each}
          </div>
        {/if}
      </div>
    </div>
  </div>
</header>
