<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import DashboardService from "../service";
    import { each } from "svelte/internal";

    export let filter;
    let loading = true;

    const columnsName = [
        {
            codename: "name",
            name: "Name",
            tooltip: "Nombre de la propiedad",
        },
        {
            codename: "total",
            name: "Total",
            tooltip: "Total",
        },
        {
            codename: "emailCount",
            name: "Emails",
            tooltip: "Cantidad de emails enviados",
        },
        {
            codename: "smsCount",
            name: "SMS",
            tooltip: "Cantidad de SMS enviados",
        },
        // {
        //     codename: "checkInCount",
        //     name: "Check-ins",
        //     tooltip: "Cantidad de Check-ins",
        // },
        // {
        //     codename: "taskCount",
        //     name: "Tasks",
        //     tooltip: "Cantidad de Tareas",
        // },
        // {
        //     codename: "normalCount",
        //     name: "Solicitudes",
        //     tooltip: "Cantidad de Solicitudes",
        // },
        // {
        //     codename: "restaurantCount",
        //     name: "Restaurant",
        //     tooltip: "Cantidad de Restaurantes",
        // },
        // {
        //     codename: "serviceCount",
        //     name: "Service",
        //     tooltip: "Cantidad de Servicios",
        // },
        // {
        //     codename: "lostFoundCount",
        //     name: "Lost & Found",
        //     tooltip: "Cantidad de Objetos Perdidos",
        // },
        // {
        //     codename: "packageCount",
        //     name: "Packages",
        //     tooltip: "Cantidad de Paquetes",
        // },
        // {
        //     codename: "othersCount",
        //     name: "Others",
        //     tooltip: "Cantidad de Otros",
        // },
    ];
    let listProperties = [];

    let currentSortColumn = "total";
    let sortOrder = "desc";

    onMount(() => {
        loading = true;
        initComponent();
    });

    const initComponent = () => {
        loading = true;
        DashboardService.propertiesListConsume(
            Object.assign({}, filter, {
                currentSortColumn,
                sortOrder,
            }),
        )
            .then((response) =>
                response.ok ? response.json() : Promise.reject(response),
            )
            .then((metrics) => {
                listProperties = metrics;
                loading = false;
            });
    };

    const setOrdenFor = (codename) => {
        if (currentSortColumn == codename) {
            sortOrder = sortOrder == "desc" ? "asc" : "desc";
        } else {
            currentSortColumn = codename;
            sortOrder = "desc";
        }
        initComponent();
    };
</script>

<div class="card">
    <div class="card-body">
        {#if loading}
            loading
        {:else}
            <h2 class="card-title">Uso de propiedades</h2>

            <table class="table table-striped table-hover table-responsive">
                <thead class="table-dark">
                    <tr>
                        {#each columnsName as column}
                            <th
                                class="sortable"
                                on:click={() => setOrdenFor(column.codename)}
                                title={column.tooltip}
                            >
                                {column.name}
                                {#if currentSortColumn == column.codename}
                                    <i class="material-icons pointer"
                                        >{sortOrder == "desc"
                                            ? "arrow_downward"
                                            : "arrow_upward"}</i
                                    >
                                {:else}
                                    <i class="material-icons pointer">sort</i>
                                {/if}
                            </th>
                        {/each}
                    </tr>
                </thead>
                <tbody>
                    {#each listProperties as property}
                        <tr>
                            {#each columnsName as column}
                                <td title={column.tooltip}
                                    >{property[column.codename]}</td
                                >
                            {/each}
                        </tr>
                    {/each}
                </tbody>
            </table>
        {/if}
    </div>
</div>
