export const environments = {
    googleRecaptchaSiteKey: window.location.hostname.includes('guesthub.io')
        ? "6LcR4bYUAAAAAF4W6jbLAqchCHzPIokZu8B5VUZc"
        : "6Lcm4bYUAAAAAJx9VKp2ON3PvaRUV0FEq1wLGvIo",
    appApi: window.location.hostname.includes('localhost')
        ? `http://localhost:3000`
        : `${window.location.origin.replace('administration.', 'app-api.')}`,
    authorizationApi: window.location.hostname.includes('localhost')
        ? `http://localhost:3002`
        : `${window.location.origin.replace('administration.', 'authorization-api.')}`,
    administrationApi: window.location.hostname.includes('localhost')
        ? `http://localhost:3005`
        : `${window.location.origin.replace('administration.', 'administration-api.')}`
};