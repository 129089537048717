<script>
  import { onMount } from "svelte/internal";
  import {
    addMessages,
    init,
    locale,
    getLocaleFromNavigator,
  } from "svelte-i18n";
  import {
    AuthService,
    isAuthenticated,
    userInformation,
  } from "./auth.service";
  import Router from "./app.routes.svelte";
  import { modeDark, getColor } from "./manager-color";

  import HeaderComponent from "./navigation/header.component.svelte";
  import SidebarComponent from "./navigation/sidebar.component.svelte";

  import localeEN from "./assets/locales/en.json";
  import localeES from "./assets/locales/es.json";

  addMessages("en", localeEN);
  addMessages("es", localeES);

  init({
    fallbackLocale: "es",
    initialLocale: getLocaleFromNavigator(),
  });

  if (localStorage.getItem("language")) {
    locale.set(localStorage.getItem("language"));
  } else {
    locale.set(getLocaleFromNavigator().split("-")[0]);
    localStorage.setItem("language", getLocaleFromNavigator().split("-")[0]);
  }

  let loading = true;
  let logoElement;

  onMount(() => {
    modeDark.subscribe((mode) => {
      Promise.resolve(getColor(mode)).then((colors) =>
        Object.keys(colors).forEach((key) => {
          document.querySelector(":root").style.setProperty(key, colors[key]);
        }),
      );
    });
    loading = true;

    new Promise((resolve, rejected) => {
      setTimeout(() => {
        logoElement.classList.add("showlogo");
        resolve(true);
      }, 200);
    })
      .then(AuthService.handlerLogin)
      .then((response) => {
        loading = false;
      })
      .catch((err) => {
        if (err.status == 401) {
          loading = false;
        } else {
          console.error(err);
        }
      });
  });
</script>

{#if loading}
  <div class="d-flex justify-content-center align-items-center vh-100 bg-dark">
    <div class="logoInitial text-center">
      <img
        bind:this={logoElement}
        src="/images/logo-dark.svg"
        alt="Logo"
        class="img-fluid"
      />
      <br />
      <div class="spinner-border text-light mt-3" role="status">
        <span class="visually-hidden">Cargando...</span>
      </div>
    </div>
  </div>
{:else if $isAuthenticated}
  <div class="d-flex flex-column vh-100">
    <!-- Navbar -->
    <HeaderComponent></HeaderComponent>

    <div class="overflow-hidden d-flex flex-grow-1">
      <!-- Sidebar -->
      <SidebarComponent></SidebarComponent>

      <!-- Contenido principal -->
      <main class="overflow-hidden d-flex flex-grow-1">
        <Router />
      </main>
    </div>
  </div>
{:else}
  <Router />
{/if}
