import Page from "./page.svelte";

export default ([
    {
        path: '',
        redirectTo: 'administrators'
    },
    {
        path: ':activeTab',
        component: Page
    }
]);