<script>
    import { onMount, createEventDispatcher } from "svelte";
    import { ClientService } from "./service";
    import {
        useForm,
        required,
        validators,
        minLength,
        maxLength,
        Hint,
        HintGroup,
    } from "svelte-use-form";
    import { userInformation } from "../auth.service";

    const dataForm = useForm();
    let loading = true;
    let userData = {
        currentPassword: "",
        newPassword: "",
        repeatPassword: "",
    };
    let currentPasswordElement;
    let currentPasswordIconElement;
    let newPasswordElement;
    let newPasswordIconElement;
    let repeatPasswordElement;
    let repeatPasswordIconElement;

    let disabledButton = false;

    $: {
        disabledButton = !$dataForm.valid;
    }

    onMount(() => {
        loading = false;
    });

    const backModal = () => dispatch("back", true);

    const dispatch = createEventDispatcher();

    const handleSubmit = (e) => {
        if ($dataForm.valid) {
            disabledButton = true;

            ClientService.UpdatePasswordById(
                {
                    id: $userInformation.id,
                },
                userData,
            )
                .then((response) =>
                    response.ok ? response : Promise.reject(response),
                )
                .then(() => {
                    dispatch("successful", true);
                })
                .catch((err) => {
                    console.error(err);
                    disabledButton = false;
                });
        }
    };

    const sameAs = (event) =>
        userData.newPassword != event ? { sameAs: true } : null;

    const toggleVisibility = (element, iconElement) => {
        if (element.type === "password") {
            iconElement.innerHTML = "visibility";
            element.type = "text";
        } else {
            iconElement.innerHTML = "visibility_off";
            element.type = "password";
        }
    };
</script>

<!-- modal-dialog-scrollable -->
{#if loading}
    <!-- Loading State -->
    <div class="modal-body">Cargando...</div>
{:else}
    <!-- Modal Header -->
    <div class="modal-header">
        <button
            type="button"
            class="btn btn-link btn-sm d-flex align-items-center"
            on:click={backModal}
        >
            <span class="material-icons">arrow_back_ios</span>
        </button>
        <h5 class="modal-title flex-grow-1">Cambiar contraseña</h5>
    </div>

    <!-- Modal Body -->
    <div class="modal-body">
        <form use:dataForm>
            <div class="modal-body">
                <div class="mb-3">
                    <label for="currentPassword" class="form-label">
                        Contraseña actual
                    </label>
                    <div class="input-group">
                        <input
                            bind:this={currentPasswordElement}
                            use:validators={[required, minLength(8)]}
                            type="password"
                            class="form-control {!$dataForm.currentPassword
                                ?.valid
                                ? 'is-invalid'
                                : 'is-valid'}"
                            name="currentPassword"
                            id="currentPassword"
                            required
                            autocomplete="off"
                            bind:value={userData.currentPassword}
                        />
                        <span class="input-group-text pointer">
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <div
                                class="material-icons"
                                on:click|preventDefault={() =>
                                    toggleVisibility(
                                        currentPasswordElement,
                                        currentPasswordIconElement,
                                    )}
                                bind:this={currentPasswordIconElement}
                            >
                                visibility_off
                            </div>
                        </span>
                    </div>

                    <HintGroup for="currentPassword">
                        <Hint on="required" class="form-text invalid-feedback"
                            >La contraseña es obligatoria.</Hint
                        >
                        <Hint
                            on="minLength"
                            class="form-text invalid-feedback"
                            hideWhen="required"
                            let:value
                            >La contraseña debe tener al menos {value}
                            caracteres.</Hint
                        >
                    </HintGroup>
                </div>
                <div class="mb-3">
                    <label for="newPassword" class="form-label">
                        Contraseña
                    </label>
                    <div class="input-group">
                        <input
                            bind:this={newPasswordElement}
                            use:validators={[required, minLength(8)]}
                            type="password"
                            class="form-control {!$dataForm.newPassword?.valid
                                ? 'is-invalid'
                                : 'is-valid'}"
                            name="newPassword"
                            id="newPassword"
                            required
                            autocomplete="off"
                            bind:value={userData.newPassword}
                        />
                        <span class="input-group-text pointer">
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <div
                                class="material-icons"
                                on:click|preventDefault={() =>
                                    toggleVisibility(
                                        newPasswordElement,
                                        newPasswordIconElement,
                                    )}
                                bind:this={newPasswordIconElement}
                            >
                                visibility_off
                            </div>
                        </span>
                    </div>

                    <HintGroup for="newPassword">
                        <Hint on="required" class="form-text invalid-feedback"
                            >La contraseña es obligatoria.</Hint
                        >
                        <Hint
                            on="minLength"
                            class="form-text invalid-feedback"
                            hideWhen="required"
                            let:value
                            >La contraseña debe tener al menos {value}
                            caracteres.</Hint
                        >
                    </HintGroup>
                </div>
                <div class="mb-3">
                    <label for="repeatPassword" class="form-label">
                        Repita nueva Contraseña
                    </label>
                    <div class="input-group">
                        <input
                            bind:this={repeatPasswordElement}
                            use:validators={[required, sameAs]}
                            type="password"
                            class="form-control {!$dataForm.repeatPassword
                                ?.valid
                                ? 'is-invalid'
                                : 'is-valid'}"
                            name="repeatPassword"
                            id="repeatPassword"
                            required
                            autocomplete="off"
                            bind:value={userData.repeatPassword}
                        />
                        <span class="input-group-text pointer">
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <div
                                class="material-icons"
                                on:click|preventDefault={() =>
                                    toggleVisibility(
                                        repeatPasswordElement,
                                        repeatPasswordIconElement,
                                    )}
                                bind:this={repeatPasswordIconElement}
                            >
                                visibility_off
                            </div>
                        </span>
                    </div>
                    <HintGroup for="repeatPassword">
                        <Hint on="required" class="form-text invalid-feedback"
                            >Repetir la contraseña es obligatorio.</Hint
                        >
                        <Hint
                            on="sameAs"
                            class="form-text invalid-feedback"
                            hideWhen="required"
                            >Las contraseñas no coinciden.</Hint
                        >
                    </HintGroup>
                </div>
            </div>
        </form>
    </div>

    <!-- Modal Footer -->
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" on:click={backModal}
            >Cerrar</button
        >
        <button
            type="submit"
            class="btn btn-primary"
            disabled={!$dataForm.valid || disabledButton}
            on:click={handleSubmit}>Guardar</button
        >
    </div>
{/if}
