<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import { startOfDay, endOfDay, subDays } from "date-fns";
    import DashboardService from "../service";

    export let chart_ref;
    export let filter;
    let metrics;

    onMount(() => {
        initComponent();

        window.addEventListener("resize", () => {
            initComponent();
        });
    });

    const initComponent = () => {
        DashboardService.checkInXProperties(filter)
            .then((response) =>
                response.ok ? response.json() : Promise.reject(response),
            )
            .then((metrics) => {
                google.charts.load("current", { packages: ["corechart"] });
                google.charts.setOnLoadCallback(drawChart);

                function drawChart() {
                    var data = google.visualization.arrayToDataTable(
                        metrics.data,
                    );

                    var options = {
                        isStacked: false,
                        is3D: true,
                        legend: { position: "bottom", maxLines: 3 },
                        chartArea: {
                            left: 40,
                            top: 30,
                            right: 20,
                            width: "90%",
                            height: "75%",
                        },
                        vAxis: {
                            minValue: 0,
                            textStyle: { color: "#686C6D" },
                            baselineColor: { color: "red" },
                        },
                        hAxis: {
                            textStyle: { color: "#686C6D" },
                            baselineColor: { color: "red" },
                        },
                        animation: {
                            startup: true,
                            duration: 3000,
                            easing: "inAndOut",
                        },
                        colors: metrics.metadata,
                    };

                    var chart = new google.visualization.AreaChart(
                        chart_ref,
                    );
                    chart.draw(data, options);
                }
            });
    };
</script>

<div class="card">
    <div class="card-body">
        <h2 class="card-title">Cantidad de ticket de check in por propiedad</h2>
        <!-- <p class="card-text">
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
        </p> -->

        <div bind:this={chart_ref} style="width: 100%; height: 500px;" />
    </div>
</div>
