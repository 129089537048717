<script>
    import { onMount, createEventDispatcher } from "svelte";
    import Permissions from "./permissions.svelte";

    const dispatch = createEventDispatcher();
    export let permissions = [];

    const checkedScope = (e) => dispatch("checked", e.detail);
    const checkedScope2 = (permission) => (e) =>
        dispatch("checked", { detail: permission });
</script>

{#each permissions as permission}
    <div class="form-check">
        <input
            class="form-check-input"
            type="checkbox"
            name="flexCheckDefault{permission.codename}"
            checked={permission.checked}
            on:change={checkedScope2(permission)}
            id="flexCheckDefault{permission.codename}"
        />
        <label
            data-toggle="collapse"
            data-target="#collapsediv1"
            class="form-check-label"
            for="flexCheckDefault{permission.codename}"
        >
            {permission.name}
        </label>
    </div>

    {#if permission.permissions}
        <div class="ms-4">
            <Permissions
                on:checked={checkedScope}
                permissions={permission.permissions}
            ></Permissions>
        </div>
    {/if}
{/each}
