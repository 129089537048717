import { environments } from "../assets/environments";
import { httpGet } from "../http-service.svelte";


export default ({
    propertiesListConsume: filter =>
        httpGet(
            `${environments.administrationApi}/dashboard/properties-consume?${filter.date[0] && filter.date[1]
                ? `fromDate=${new Date(filter.date[0]).toISOString()}&toDate=${new Date(filter.date[1]).toISOString()}`
                : ``
            }${filter.currentSortColumn && filter.sortOrder
                ? `&sortBy=${filter.currentSortColumn}&order=${filter.sortOrder}`
                : ``
            }`
        ),
    checkInXProperties: filter =>
        httpGet(
            `${environments.administrationApi}/dashboard/check-in-x-properties?${filter.date[0] && filter.date[1]
                ? "fromDate=" +
                new Date(filter.date[0]).toISOString() +
                "&toDate=" +
                new Date(filter.date[1]).toISOString()
                : ""
            }`
        ),
    lostFoundXProperties: filter =>
        httpGet(
            `${environments.administrationApi}/dashboard/lost-found-x-properties?${filter.date[0] && filter.date[1]
                ? "fromDate=" +
                new Date(filter.date[0]).toISOString() +
                "&toDate=" +
                new Date(filter.date[1]).toISOString()
                : ""
            }`
        ),
    requestsXProperties: filter =>
        httpGet(
            `${environments.administrationApi}/dashboard/requests-x-properties?${filter.date[0] && filter.date[1]
                ? "fromDate=" +
                new Date(filter.date[0]).toISOString() +
                "&toDate=" +
                new Date(filter.date[1]).toISOString()
                : ""
            }`
        ),
    restaurantsXProperties: filter =>
        httpGet(
            `${environments.administrationApi}/dashboard/restaurants-x-properties?${filter.date[0] && filter.date[1]
                ? "fromDate=" +
                new Date(filter.date[0]).toISOString() +
                "&toDate=" +
                new Date(filter.date[1]).toISOString()
                : ""
            }`
        ),
    servicesXProperties: filter =>
        httpGet(
            `${environments.administrationApi}/dashboard/services-x-properties?${filter.date[0] && filter.date[1]
                ? "fromDate=" +
                new Date(filter.date[0]).toISOString() +
                "&toDate=" +
                new Date(filter.date[1]).toISOString()
                : ""
            }`
        ),
    tasksXProperties: filter =>
        httpGet(
            `${environments.administrationApi}/dashboard/tasks-x-properties?${filter.date[0] && filter.date[1]
                ? "fromDate=" +
                new Date(filter.date[0]).toISOString() +
                "&toDate=" +
                new Date(filter.date[1]).toISOString()
                : ""
            }`
        )
});
