import { environments } from "../assets/environments";
import { httpPut, httpGet } from "../http-service.svelte";

const UpdateClientById = (filter, data) =>
    httpPut(
        `${environments.api}/client/${filter.id}`,
        data
    );

const UpdatePasswordById = (filter, data) =>
    httpPut(
        `${environments.api}/client/${filter.id}/password`,
        data
    );

const UpdateEmailById = (filter, data) =>
    httpPut(
        `${environments.api}/client/${filter.id}/email`,
        data
    );

const GetClientById = (filter) =>
    httpGet(
        `${environments.api}/client/${filter.id}`
    );

export const ClientService = ({
    UpdateClientById,
    UpdatePasswordById,
    UpdateEmailById,
    GetClientById
});
