import { environments } from "../assets/environments";
import { isAuthenticated, userInformation, Authentication } from "../auth.service";
import { httpDelete, httpGet, httpPost } from "../http-service.svelte";

const logout = () =>
    Promise.resolve(
        localStorage.clear()
    ).then(() => {
        Authentication.access_token = null;
        userInformation.set(null);
        isAuthenticated.set(false);
        window.location.href = "/";

        return true;
    });

const setLogin = (data) =>
    httpPost(
        `${environments.authorizationApi}/administration/login`,
        data
    ).then(response => response.ok ? response.json() : Promise.reject(response))
        .then(response => {
            Authentication.access_token = response.access_token;
            localStorage.setItem('auth', JSON.stringify({
                access_token: response.access_token
            }));
            userInformation.set(response);
            isAuthenticated.set(true);

            return true;
        });

const getLogin = () =>
    httpGet(
        `${environments.authorizationApi}/administration/login`
    ).then(response => response.ok ? response.json() : Promise.reject(response))
        .then(response => {
            userInformation.set(response);
            isAuthenticated.set(true);

            return true;
        });

export const LoginService = ({
    setLogin,
    getLogin,
    logout
});
