<script context="module">
  import { Authentication } from "./auth.service";

  export const httpGet = (url, option) => {
    return fetch(url, {
      method: "GET",
      headers: Object.assign(
        {
          Accept: "application/json",
          "Content-Type": "application/json",
          language: localStorage.getItem("lang") || "en",
        },
        Authentication.access_token
          ? {
              Authorization: `${Authentication.access_token}`,
            }
          : {},
      ),
      mode: "cors",
      //credentials: "include"
    });
  };

  export const httpPost = (url, payload, option) => {
    return fetch(url, {
      method: "POST",
      headers: Object.assign(
        {
          "Content-Type": "application/json",
          language: localStorage.getItem("lang") || "en",
        },
        Authentication.access_token
          ? {
              Authorization: `${Authentication.access_token}`,
            }
          : {},
      ),
      mode: "cors",
      body: JSON.stringify(payload),
      //credentials: "include"
    }).then((response) => (response.ok ? response : Promise.reject(response)));
  };

  export const formDataPost = (url, formData) => {
    return fetch(url, {
      method: "POST",
      body: formData,
      headers: Object.assign(
        {
          language: localStorage.getItem("language") || "en",
          Accept: "*/*",
        },
        Authentication.access_token
          ? {
              Authorization: `${Authentication.access_token}`,
            }
          : {},
      ),
      mode: "cors",
      //credentials: "include"
    }).then((response) => (response.ok ? response : Promise.reject(response)));
  };

  export const httpPut = (url, payload, option) => {
    return fetch(url, {
      method: "PUT",
      headers: Object.assign(
        {
          Accept: "application/json",
          "Content-Type": "application/json",
          language: localStorage.getItem("lang") || "en",
        },
        Authentication.access_token
          ? {
              Authorization: `${Authentication.access_token}`,
            }
          : {},
      ),
      mode: "cors",
      body: JSON.stringify(payload),
      //credentials: "include"
    }).then((response) => (response.ok ? response : Promise.reject(response)));
  };

  export const httpDelete = (url, payload, option) => {
    return fetch(url, {
      method: "DELETE",
      headers: Object.assign(
        {
          Accept: "application/json",
          "Content-Type": "application/json",
          language: localStorage.getItem("lang") || "en",
        },
        Authentication.access_token
          ? {
              Authorization: `${Authentication.access_token}`,
            }
          : {},
      ),
      mode: "cors",
      body: JSON.stringify(payload),
      //credentials: "include"
    }).then((response) => (response.ok ? response : Promise.reject(response)));
  };
</script>
