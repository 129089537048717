<script>
  import { onDestroy, onMount } from "svelte/internal";
  import router from "page";
  import { isAuthenticated, userInformation } from "./auth.service";
  import { activeRoute, params, queryParams, redirect } from "./routeStore";
  import { routes } from "./Routes";
  import Error404Page from "./errors/404.svelte";

  let disabled = false;

  onMount(() => {
    routes.forEach((route) => {
      router(
        route.path,

        (ctx, next) => {
          disabled = true;
          if (route.redirectTo) {
            redirect(ctx.path + "/" + route.redirectTo);
            return;
          }
          $activeRoute = {
            ...route,
            path: ctx.path,
            pathname: ctx.pathname,
            routePath: ctx.routePath,
          };
          $params = ctx.params;
          $queryParams = ctx.querystring
            ? ctx.querystring.split("&").reduce((r, c) => {
                r[c.split("=")[0]] = c.split("=")[1];
                return r;
              }, {})
            : {};
          next();
        },

        () => {
          setTimeout(() => {
            disabled = false;
            if (!$isAuthenticated && route.isAuthenticated) {
              redirect("/login");
            } else if ($isAuthenticated && !route.isAuthenticated) {
              redirect("/");
            } else {
            }
          }, 5);
        },
      );
    });

    // Start the router
    router.start();
  });

  onDestroy(() => {
    router.stop();
  });
</script>

{#if disabled}
  <slot />
{:else if $activeRoute.component}
  <svelte:component this={$activeRoute.component} {...$$restProps} />
{:else}
  <svelte:component this={Error404Page} {...$$restProps} />
{/if}
