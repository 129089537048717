<script>
  import { onMount } from "svelte";
  import { userInformation } from "../auth.service";

  let loading = true;
  let tenantInformation = null;

  onMount(() => {
    loading = true;
  });
</script>

<!-- Sección de contenido -->
<section class="section-base d-flex justify-content-center align-items-center">
  <div class="container text-center my-5" style="max-width: 800px;">
    <h1>
      <i class="material-icons" style="font-size: 48px;">construction</i>
    </h1>
    <h1 class="mb-3">Inicio</h1>

    <p class="mt-5">Estamos trabajando!</p>
  </div>
</section>
