<script>
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import HeaderComponent from "../components/header.component.svelte";
    import DatePickerComponent from "../components/date-picker.component.svelte";

    import PropertiesConsumeComponent from "./widgets/properties-consume.svelte";
    import CheckInXPropertiesComponent from "./widgets/check-in-x-properties.svelte";
    import LostFoundXPropertiesComponent from "./widgets/lost-found-x-properties.svelte";
    import RequestsXPropertiesComponent from "./widgets/requests-x-properties.svelte";
    import RestaurantsXPropertiesComponent from "./widgets/restaurants-x-properties.svelte";
    import ServicesXPropertiesComponent from "./widgets/services-x-properties.svelte";
    import TasksXPropertiesComponent from "./widgets/tasks-x-properties.svelte";
    import {
        startOfMonth,
        endOfMonth,
        endOfDay,
        startOfDay,
        subDays,
    } from "date-fns";

    const filter = {
        date: [startOfMonth(Date.now()), endOfMonth(Date.now())],
    };

    let loading = true;
    let configTitle = {
        breadcrumb: [
            { title: `Home`, link: `/` },
            {
                title: `Dashboard`,
                link: `/dashboard`,
            },
        ],
        title: `Dashboard`,
        description: ``,
        buttons: [],
    };

    onMount(() => {
        loading = true;

        initComponent();
    });

    const initComponent = () => {
        loading = true;
        loading = false;
    };

    const onChangeDate = (e) => {
        filter.date = e.detail;
        loading = true;
        setTimeout(() => (loading = false), 500);
    };

    const onResetDate = () => {
        filter.date = [
            startOfDay(subDays(Date.now(), 30)),
            endOfDay(Date.now()),
        ];
    };
</script>

{#if !loading}
    <section class="container d-flex flex-column mh-100">
        <div class="d-flex flex-column">
            <HeaderComponent bind:config={configTitle}></HeaderComponent>
        </div>

        <!-- Sección de contenido -->
        <div class="overflow-auto mh-100 flex-grow-1">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <DatePickerComponent
                                bind:date={filter.date}
                                on:change={onChangeDate}
                                on:reset={onResetDate}
                            ></DatePickerComponent>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <!-- Contenido de Tabs -->

                    {#if !loading}
                        <PropertiesConsumeComponent {filter}
                        ></PropertiesConsumeComponent>
                        <RequestsXPropertiesComponent {filter}
                        ></RequestsXPropertiesComponent>
                        <RestaurantsXPropertiesComponent {filter}
                        ></RestaurantsXPropertiesComponent>
                        <ServicesXPropertiesComponent {filter}
                        ></ServicesXPropertiesComponent>
                        <TasksXPropertiesComponent {filter}
                        ></TasksXPropertiesComponent>
                        <CheckInXPropertiesComponent {filter}
                        ></CheckInXPropertiesComponent>
                        <LostFoundXPropertiesComponent {filter}
                        ></LostFoundXPropertiesComponent>
                    {/if}
                </div>
            </div>
        </div>
    </section>
{/if}
