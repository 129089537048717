<script>
    import { onMount, createEventDispatcher } from "svelte";
    import { _ } from "svelte-i18n";
    import { AdministratorsService } from "./service";
    import {
        useForm,
        required,
        validators,
        maxLength,
        minLength,
        Hint,
        HintGroup,
        email,
    } from "svelte-use-form";
    import { userInformation } from "../../../auth.service";

    export let user = null;
    const dataForm = useForm();
    let loading = true;
    let dataUser = {
        firstName: "",
        lastName: "",
        email: "",
        userTypeId: null,
    };
    let userTypes = [];
    let disabledButton = false;
    let modalBackdrop;

    $: {
        disabledButton = !$dataForm.valid;
    }

    onMount(() => {
        modalBackdrop.addEventListener("click", closeModal);

        loading = true;
        AdministratorsService.listUserTypes()
            .then((response) =>
                response.ok ? response.json() : Promise.reject(response),
            )
            .then((usertypes) => {
                userTypes = usertypes;
                if (user) {
                    AdministratorsService.detail(user.id)
                        .then((response) =>
                            response.ok
                                ? response.json()
                                : Promise.reject(response),
                        )
                        .then((response) => {
                            dataUser = Object.assign({}, dataUser, response);
                            loading = false;
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                } else {
                    loading = false;
                }
            })
            .catch((err) => {
                console.error(err);
                closeModal();
            });
    });

    const closeModal = () => dispatch("close", true);

    const dispatch = createEventDispatcher();

    const handleSubmit = (e) => {
        disabledButton = true;
        if (dataUser.id) {
            AdministratorsService.update(dataUser.id, dataUser)
                .then((response) =>
                    response.ok ? response : Promise.reject(response),
                )
                .then((response) => {
                    dispatch("successful", true);
                })
                .catch((err) => {
                    console.error(err);
                    disabledButton = false;
                });
        } else {
            AdministratorsService.create(dataUser)
                .then((response) =>
                    response.ok ? response.json() : Promise.reject(response),
                )
                .then((response) => {
                    dispatch("successful", response);
                })
                .catch((err) => {
                    console.error(err);
                    disabledButton = false;
                });
        }
    };
</script>

<!-- modal-dialog-scrollable -->
<div bind:this={modalBackdrop} class="modal-backdrop fade show" />
<div class="modal modal-right fade show" role="dialog">
    <div class="modal-content">
        {#if loading}
            <!-- Loading State -->
            <div class="modal-body">Cargando...</div>
        {:else}
            <!-- Modal Header -->
            <div class="modal-header">
                <h5 class="modal-title">
                    {#if user}
                        Modificar {user.firstName} {user.lastName}
                    {:else}
                        Agregar administrador
                    {/if}
                </h5>
                <button type="button" class="btn-close" on:click={closeModal}
                ></button>
            </div>

            <!-- Modal Body -->
            <div class="modal-body">
                <form use:dataForm>
                    <div class="mb-3">
                        <label for="firstName" class="form-label">
                            {$_("First Name")}
                        </label>
                        <!-- svelte-ignore a11y-autofocus -->
                        <input
                            use:validators={[
                                required,
                                minLength(3),
                                maxLength(20),
                            ]}
                            type="text"
                            class="form-control {!$dataForm.firstName?.valid
                                ? 'is-invalid'
                                : 'is-valid'}"
                            name="firstName"
                            id="firstName"
                            required
                            autofocus
                            autocomplete="off"
                            minlength="3"
                            maxlength="20"
                            bind:value={dataUser.firstName}
                        />
                        <HintGroup for="firstName">
                            <Hint
                                on="required"
                                class="form-text invalid-feedback"
                                >{$_("The field is mandatory.")}</Hint
                            >
                            <!-- Se muestra solo si el campo no está vacío (hideWhen="required") -->
                            <Hint
                                on="minLength"
                                class="form-text invalid-feedback"
                                hideWhen="required"
                                let:value
                                >{$_("The name must have at least")}
                                {value}
                                {$_("character.")}</Hint
                            >
                            <!-- Se muestra solo si el campo supera la longitud mínima (hideWhen="minLength") -->
                            <Hint
                                on="maxLength"
                                class="form-text invalid-feedback"
                                hideWhen="minLength"
                                let:value
                                >{$_("The name must not exceed")}
                                {value}
                                {$_("characters.")}</Hint
                            >
                        </HintGroup>
                    </div>
                    <div class="mb-3">
                        <label for="lastName" class="form-label">
                            {$_("Last Name")}
                        </label>
                        <input
                            use:validators={[
                                required,
                                minLength(3),
                                maxLength(20),
                            ]}
                            type="text"
                            class="form-control {!$dataForm.lastName?.valid
                                ? 'is-invalid'
                                : 'is-valid'}"
                            name="lastName"
                            id="lastName"
                            required
                            autocomplete="off"
                            minlength="3"
                            maxlength="20"
                            bind:value={dataUser.lastName}
                        />
                        <HintGroup for="lastName">
                            <Hint
                                on="required"
                                class="form-text invalid-feedback"
                                >{$_("The field is mandatory.")}</Hint
                            >
                            <!-- Se muestra solo si el campo no está vacío (hideWhen="required") -->
                            <Hint
                                on="minLength"
                                class="form-text invalid-feedback"
                                hideWhen="required"
                                let:value
                                >{$_("The name must have at least")}
                                {value}
                                {$_("character.")}</Hint
                            >
                            <!-- Se muestra solo si el campo supera la longitud mínima (hideWhen="minLength") -->
                            <Hint
                                on="maxLength"
                                class="form-text invalid-feedback"
                                hideWhen="minLength"
                                let:value
                                >{$_("The name must not exceed")}
                                {value}
                                {$_("characters.")}</Hint
                            >
                        </HintGroup>
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">
                            {$_("Email")}
                        </label>
                        <input
                            use:validators={[required, email]}
                            type="email"
                            class="form-control {!$dataForm.email?.valid
                                ? 'is-invalid'
                                : 'is-valid'}"
                            name="email"
                            id="email"
                            required
                            autocomplete="off"
                            bind:value={dataUser.email}
                        />
                        <HintGroup for="email">
                            <Hint
                                on="required"
                                class="form-text invalid-feedback"
                                >{$_("Email is mandatory.")}</Hint
                            >
                            <Hint
                                on="email"
                                class="form-text invalid-feedback"
                                hideWhen="required"
                                >{$_("The email format is not correct.")}</Hint
                            >
                        </HintGroup>
                    </div>

                    <div class="mb-3">
                        <label for="usertype" class="col-form-label"
                            >User type:</label
                        >
                        <select
                            class="form-select"
                            id="usertype"
                            name="usertype"
                            bind:value={dataUser.userTypeId}
                        >
                            <option selected disabled value={null}>
                                Select user type
                            </option>
                            {#each userTypes as userType}
                                <option value={userType.id}
                                    >{userType.name}</option
                                >
                            {/each}
                        </select>
                    </div>
                </form>
            </div>

            <!-- Modal Footer -->
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    on:click={closeModal}>Cerrar</button
                >
                <button
                    type="submit"
                    class="btn btn-primary"
                    disabled={!$dataForm.valid || disabledButton}
                    on:click={handleSubmit}>Guardar</button
                >
            </div>
        {/if}
    </div>
</div>
