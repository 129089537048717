<script>
    import { onMount, onDestroy } from "svelte";
    import { isSidebarCollapsed, isMobile } from "./service";
    import { activeRoute } from "../routeStore";

    let loading = true;
    let sidebarElement;

    const closeMenuOnClickOutside = (event) => {
        let collapsedInMObile = false;
        sidebarElement
            .querySelectorAll(".navbar .nav-item")
            .forEach((element) => {
                if (
                    element.contains(event.target) &&
                    !element.classList.contains("dropdown")
                ) {
                    collapsedInMObile = true;
                }
            });
        if (
            (!sidebarElement.contains(event.target) || collapsedInMObile) &&
            window.innerWidth < 992
        ) {
            $isSidebarCollapsed = true;
        }
    };

    const handleResize = () => {
        // Actualiza el estado 'isMobile' según el ancho de la ventana
        $isMobile = window.innerWidth < 768;
        // En móviles, inicia con el sidebar oculto (colapsado)
        if ($isMobile) {
            $isSidebarCollapsed = true; // Asegura que el sidebar esté oculto en móviles
        } else {
            // En desktop o tablet, ajusta el estado inicial si es necesario
            $isSidebarCollapsed = window.innerWidth < 992; // Por ejemplo, colapsado en tablet
        }
    };

    window.addEventListener("click", closeMenuOnClickOutside);

    window.addEventListener("resize", handleResize);
    handleResize();

    onMount(() => {
        // Cerrar el menú al hacer clic en un enlace del menú
        sidebarElement.querySelectorAll(".nav-link").forEach((element) => {
            element.addEventListener("click", closeMenuOnClickOutside);
        });
        initComponent();
    });

    onDestroy(() => {
        window.removeEventListener("click", closeMenuOnClickOutside);
        window.removeEventListener("resize", handleResize);
    });

    const initComponent = () => {
        loading = true;
        loading = false;
    };
</script>

<aside
    bind:this={sidebarElement}
    class="sidebar {$isSidebarCollapsed
        ? 'collapsed'
        : ''} overflow-auto d-flex flex-column h-100"
    class:is-mobile={$isMobile}
>
    {#if loading}
        Loading
    {:else}
        <nav class="navbar p-3">
            <ul class="navbar-nav flex-column w-100">
                <li class="nav-item">
                    <hr class="my-2" />
                </li>

                <li class="nav-item {$activeRoute.path == `/` ? 'active' : ''}">
                    <a class="nav-link d-flex align-items-center" href="/">
                        <span class="position-relative">
                            <span class="material-icons"> home </span>
                        </span>
                        <span class="nav-text ms-2">Inicio</span>
                    </a>
                </li>

                <li
                    class="nav-item {$activeRoute.path == `/dashboard`
                        ? 'active'
                        : ''}"
                >
                    <a
                        class="nav-link d-flex align-items-center"
                        href="/dashboard"
                    >
                        <span class="position-relative">
                            <span class="material-icons"> dashboard </span>
                        </span>
                        <span class="nav-text ms-2">Dashboard</span>
                    </a>
                </li>

                <li class="nav-item">
                    <hr class="my-2" />
                </li>
            </ul>
        </nav>

        <div class="flex-grow-1"></div>

        <nav class="navbar p-3">
            <ul class="navbar-nav flex-column w-100">
                <!-- Link Settings -->
                <li
                    class="nav-item {$activeRoute.path &&
                    $activeRoute.path.includes(`/settings`)
                        ? 'active'
                        : ''}"
                >
                    <a
                        class="nav-link d-flex align-items-center"
                        href="/settings"
                    >
                        <span class="position-relative">
                            <span class="material-icons"> settings </span>
                            <!-- <span
                                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                            >
                                3
                            </span> -->
                        </span>
                        <span class="nav-text ms-2">Settings</span>
                    </a>
                </li>
            </ul>
        </nav>
    {/if}
</aside>
